import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Button, Card, OverlayTrigger, Tooltip } from "react-bootstrap"
import { FeedBenefitsModal } from "../FeedBenefitsModal/FeedBenefitsModal";
import { FeedBenefitsForm } from "../FeedBenefitsModal/FeedBenefitsForm";
import { CustomImage } from "../CustomImage/CustomImage";
import { useImage } from "../../hooks/image/useImage";
import { useNavigate } from "react-router-dom";
import { useCookies } from "../../hooks/cookies/useCookies";
import { WarrantyModal } from "../FeedBenefitsModal/WarrantyModal";
import useConfig from "../../hooks/config/useConfig"
import { NotarialModal } from "../FeedBenefitsModal/NotarialModal";
import useUser from "../../hooks/user/useUser"

interface PropertyBenefitsType {
    badge?: string,
    image: string,
    title: string,
    text: string,
    type: string
}

export const FeedBenefitsCard = ({ benefit, source }: { benefit: PropertyBenefitsType, source: "home" | "feed" }) => {
    const [showWarranty, setShowWarranty] = useState(false);
    const [showMortgage, setShowMortgage] = useState(false);
    const [showNotarial, setShowNotarial] = useState(false);
    const optimizeSrc = useImage({ src: benefit.image, width: 200, height: 200 })
    const navigate = useNavigate()
    const { getCookie, setCookie } = useCookies();
    const [openTooltip , setOpenTooltip] = useState(true)
    const {translation, country} = useConfig()
    const { userState, isLoggedIn } = useUser()

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const notarial = queryParams.get('notarial');

    const handleShow = (type: string) => {
        
        if(!isLoggedIn){
            navigate('/iniciar-sesion')
            return;
        }

        if (type === 'warranty') {
            setShowWarranty(true)
        } else if (type == 'mortgage') {
            setShowMortgage(true)
        } else if (type == 'notarial') {
            setShowNotarial(true)
        } else if (type == 'warranty_status') {
            navigate('/garantias-alquiler')
        }
    };

    const closeTooltip = () => {
        setCookie("showWarrantyFeed", "=1;expires=-1;path=/;SameSite=none;Secure");
        setOpenTooltip(false);
    }

    useEffect(() => {
        if (!getCookie("showWarrantyFeed")) {
          setOpenTooltip(true);
        } else {
          setOpenTooltip(false);
        }
        // abrir modal notarial si existe el param ?notarial=true 
        if (notarial == "true" && !showNotarial) { 
            setShowNotarial(true);
        } else if (notarial !== "true" && showNotarial) {
            setShowNotarial(false);
        }
    }, [notarial, showNotarial]);
      
    return (
        <>

            <OverlayTrigger key={`top-${benefit.type}`}
                placement="top"
                show={openTooltip && benefit.type == 'warranty_status'}
                delay={{ show: 250, hide: 400 }}
                overlay={(props) => (
                    <Tooltip className="alert-new" {...props}>
                        <Button className="alert-new-close" onClick={closeTooltip}>
                            <i className="bi bi-x-lg"></i>
                        </Button>
                        <div className="pt-2 pe-3 text-start">
                            <span className="fw-bold">¡NUEVO!</span>
                            <span> Ahora podés ver el estado de tus {translation.warranty}s</span>
                        </div>
                    </Tooltip>
                )}
            >
                <Card className={`custom-card ${source} bg-white  shadow-sm border-0 position-relative`}>
                    <div className="left-side">
                        {
                            country == 'UY' && benefit.badge &&
                            <CustomImage
                                src={benefit.badge}
                                alt={benefit.title}
                                classes="mortgage-badge position-absolute"
                                styles={{ height: '35px' }}
                                width={10}
                                height={10}
                            />
                        }
                        
                        <Card.Img variant="top" src={optimizeSrc} className={benefit.type == 'warranty_status' ? 'warranty-status' : ''} />
                    </div>
                    <Card.Body>
                        <Card.Title className="text-green text-bold text-uppercase">{benefit.title}</Card.Title>
                        <Card.Text dangerouslySetInnerHTML={{
                            __html: benefit.text,
                        }}>
                        </Card.Text>
                        <Button variant="primary"
                            className="fw-500 py-2 px-4 text-nowrap"
                            onClick={() => handleShow(benefit.type)}
                        >
                            {benefit.type == 'warranty_status' ? 'Consultar' : 'Deriva ahora'}
                        </Button>
                    </Card.Body>
                </Card>
            </OverlayTrigger>

            <WarrantyModal showWarranty={showWarranty} setShowWarranty={setShowWarranty} />

            <FeedBenefitsModal show={showMortgage} setShow={setShowMortgage} type={benefit.type}>
                <div className="row gx-5">
                    <div className="col px-5">
                        <p className="mb-3">Si tenés un cliente potencial, derivá sus datos al sistema de referidos de BBVA y recibí <strong>una bonificación al cierre del negocio.</strong></p>

                        <ul className="ps-3 d-grid gap-3">
                            <li>Completá los <strong>datos del cliente</strong></li>
                            <li>BBVA se encargará de gestionar el análisis del crédito, aprobarlo y contactarse con el cliente</li>
                            <li><strong>Una vez cerrado el proceso, se acreditará la bonificación al profesional inmobiliario que refirió el cliente</strong> (El cierre del crédito tiene un plazo de gestión variable, las bonificaciones se pagan a mes cerrado).</li>
                        </ul>
                    </div>
                    <div className="col px-5">
                        <FeedBenefitsForm show={showMortgage} type={benefit.type} onSend={() => setShowMortgage(false)} />
                    </div>
                </div>
            </FeedBenefitsModal>

            <NotarialModal 
                show={showNotarial}
                setShow={setShowNotarial}
            />
        </>
    )
}